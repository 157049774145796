<!-- =========================================================================================
    File Name: DividerDefault.vue
    Description: Rendering of default divider
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card class="shadow-none rounded-none">

    <vs-row class="core-skill-header">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" class="mb-lg-0 mb-3">
        <div class="vx-col w-full">
          <label><h2 class="mb-0">Rating</h2></label>
          <ul class="demo-alignment">
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="1" @change="onChangeRating($event)">
                1
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="2" @change="onChangeRating($event)">
                2
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="3" @change="onChangeRating($event)">
                3
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="4" @change="onChangeRating($event)">
                4
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="5" @change="onChangeRating($event)">
                5
              </vs-radio>
            </li>
          </ul>
        </div>
      </vs-col>

    </vs-row>

    <div class="mt-5">

      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="9" vs-sm="8" vs-xs="12">
          <p class="h4">Resources</p>
        </vs-col>
        <vs-divider/>
      </vs-row>
      <template v-if="fastCheckDevelopments.length > 0">

        <vs-row :key="index" v-for="(item,index) in fastCheckDevelopments">
          <vs-col vs-justify="center" vs-align="center" vs-lg="9" vs-sm="8" vs-xs="12">
            <p class="h4"><a :href="item.link || item.document" target="_blank">{{item.title}}</a></p>
            <p>{{item.description}}</p>
          </vs-col>
        </vs-row>
      </template>
      <template v-else>

        <vs-row>
          <vs-col vs-justify="center" vs-align="center" vs-w="9" class="text-center">
            <p>No Fast Check Resource found.</p>
          </vs-col>
          <vs-divider/>
        </vs-row>
      </template>
    </div>
  </vx-card>
</template>

<script>

  import {mapActions} from "vuex";


  export default {
    data() {
      return {
        rating: this.$route.params.rating,
        fastChecks: {},

        fastChecksOptions: [],
        fastCheckFilter: {},

        fastCheckDevelopments: {},
        fastCheckDevelopmentID: null,
        currentResource: {}
      }
    },

    methods: {
      ...mapActions("fastCheck", [
        "deleteDirectorFastCheckDevelopment",
        "getDirectorFastChecksDevelopment",
        "fetchFastChecks",
        "setRating"

      ]),

      onChangeRating(event) {
        this.setRating(this.rating)
        this.getFastCheckDev();
      },
      getFastCheckDev() {
        let filter = {
          fastCheckId: this.$route.params.fastCheckPresetId,
          rating: this.rating,
        }

        this.$vs.loading()
        this.getDirectorFastChecksDevelopment(filter)
          .then((res) => {
            this.$vs.loading.close()
            this.fastCheckDevelopments = this.$store.state.fastCheck.fastChecksDevelopments;
          })
          .catch(err => {
            this.$vs.loading.close()
            console.error(err)
          })

      }
    },
    async mounted() {
      await this.getFastCheckDev();

    },
    async created() {
      this.setRating(this.rating)


    },
  }
</script>
